import types from "./types";

export const setBooksLoadingStatus = (payload) => ({
    type: types.SET_LOADING_STATE,
    payload,
});

export const setBooks = (payload) => ({
    type: types.SET_BOOKS,
    payload,
});

export const fetchBooks = (payload) => ({
    type: types.FETCH_BOOKS,
    payload
});

export const searchBook = payload => ({
    type: types.SEARCH_BOOK,
    payload
});

export const toggleNewBooks = () => ({
    type: types.TOGGLE_NEW
});

export const filterByCategory = payload => ({
    type: types.FILTER_BY_CATEGORY,
    payload
});

export const createBookAction = payload => ({
    type: types.CREATE_BOOK,
    payload
});

export const pushBookAction = payload => ({
    type: types.PUSH_BOOK,
    payload
});

export const deleteBook = payload => ({
    type: types.DELETE_BOOK,
    payload
});

export const updateBookAction = payload => ({
    type: types.UPDATE_BOOK_ACTION,
    payload
});

export const updateBookRequest = payload => ({
    type: types.UPDATE_BOOK_REQUEST,
    payload
});

export const toFavouriteAction = payload => ({
    type: types.TO_FAVOURITE,
    payload
});

export const fromFavouriteAction = payload => ({
    type: types.FROM_FAVOURITE,
    payload
});

export const getBookAction = payload => ({
    type: types.GET_BOOK,
    payload
});

export const returnBookAction = payload => ({
    type: types.RETURN_BOOK,
    payload
});