import {call, all, put, takeLatest} from 'redux-saga/effects';
import { notification } from 'antd';

import types from "./types";
import { setUsers, pushUser, setUsersLoadingStatus, setMeLoadingStatus, setToken, setMe, updateUserAction, logout } from "./actions";

import Api from "core/api";
import axiosInstance from "core/axios";

const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
}

function* getAllUsers() {

    yield put(setUsersLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.users.getAllUsers);
        yield put(setUsers(response.data));
        yield put(setUsersLoadingStatus("LOADED"))

    } catch (error) {
        console.log(error)
        yield put(setUsersLoadingStatus("ERROR"))

    }
}

function* getAllUsersFlow() {
    yield takeLatest(types.FETCH_USERS, getAllUsers)
}

function* singInSaga({ payload }) {
    try {
        const response = yield call(Api.auth.signIn, payload);
        yield put(setToken(response.data));

        headers['Authorization'] = `Bearer ${response.data.token}`;
        axiosInstance.defaults.headers = headers;

        yield call(getMe)

    } catch (error) {
        console.log(error)
        notification.error({
            message: 'Ошибка авторизации',
            description: "неверный логин или пароль"
        });
    }
}

function* singInSagaFlow() {
    yield takeLatest(types.FETCH_TOKEN, singInSaga)
}

function* getMe() {
    yield put(setMeLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.auth.getMeApi);

        yield put(setMe(response.data));
        yield put(setMeLoadingStatus("LOADED"))

        // window.location.href = '/';
    } catch (error) {
        yield put(setMeLoadingStatus("ERROR"))
        if (error.message === "Request failed with status code 403") {
            yield put(logout());
            notification.error({
                message: 'Сессия истекла'
            });
            // window.location.href = '/login';
        }
        console.log(error)
        notification.error({
            message: 'Что то пошло не так'
        });
    }
}

function* getMeFlow() {
    yield takeLatest(types.FETCH_ME, getMe)
}



function* createUser({payload}) {
    console.log('create')
    yield put(setUsersLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.users.createUser, payload);

        yield put(pushUser(response.data));
        yield put(setUsersLoadingStatus("LOADED"))

        notification.success({
            message: 'Пользователь добавлен'
        });

    } catch (error) {
        console.log(error)
        yield put(setUsersLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка добавления пользователя'
        });
    }
}

function* createUserFlow() {
    yield takeLatest(types.CREATE_USER, createUser)
}



function* updateUserSaga({payload}) {

    yield put(setUsersLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.users.updateUser, payload);
        yield put(updateUserAction(response.data));
        yield put(setUsersLoadingStatus("LOADED"))
        notification.success({
            message: 'Пользователь изменен'
        });

    } catch (error) {
        console.log(error)
        yield put(setUsersLoadingStatus("ERROR"))
        notification.success({
            message: 'Ошибка изменения пользователя'
        });
    }
}

function* updateUserFlow() {
    yield takeLatest(types.UPDATE_USER_REQUEST, updateUserSaga)
}



function* deleteUser({payload}) {
    yield put(setUsersLoadingStatus("LOADING"))
    try {
        yield call(Api.users.deleteUserApi, payload);
        yield put(setUsersLoadingStatus("LOADED"))
        notification.success({
            message: 'Пользователь удален'
        });
    } catch (error) {
        console.log(error)
        yield put(setUsersLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка удаления пользователя'
        });
    }
}

function* deleteUserFlow() {
    yield takeLatest(types.DELETE_USER, deleteUser)
}

function* updateMeSaga({payload}) {
    try {
        const response = yield call(Api.auth.updateMeApi, payload);

        notification.success({
            message: 'Профиль отредактирован'
        });

    } catch (error) {
        console.log(error)
        notification.error({
            message: 'Ошибка редактирования профиля'
        });
    }
}

function* updateMeFlow() {
    yield takeLatest(types.UPDATE_ME, updateMeSaga)
}





export default function* usersSaga() {
    yield all([
        getAllUsersFlow(),
        singInSagaFlow(),
        getMeFlow(),
        createUserFlow(),
        updateUserFlow(),
        updateMeFlow(),
        deleteUserFlow(),
    ])
}