import React from "react";
import { Route, Switch } from 'react-router-dom';

import Books from "../pages/Books";
import Users from "../pages/Users";
import Login from "../pages/Login";
import Categories from "../pages/Categories";

import PrivateRoute from "components/PrivateRoute";

const Routes = () => {

    return (
        <Switch>
            <PrivateRoute path="/users" component={Users}/>
            <PrivateRoute path="/categories" component={Categories}/>
            <Route path="/login" component={Login}/>
            <PrivateRoute path="/" component={Books}/>
        </Switch>
    )
}

export default Routes;