import axiosInstance from "../axios";

export default {
    uploadFile: fileData => {
        let formData = new FormData();
        formData.append("file", fileData)
        return axiosInstance.post(`/wp/v2/media`, formData)
    },

}
