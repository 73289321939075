import React from "react";
import Routes from "routes";
import {getMe, setMeLoadingStatus} from "store/ducks/users/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
      dispatch(getMe());
    }
    else {
      dispatch(setMeLoadingStatus("ERROR"));
      history.push('/login')
    }

  }, []);

  return (
      <Routes />
  );
}

export default App;
