import types from "./types";

export const setUsersLoadingStatus = (payload) => ({
    type: types.SET_USER_LOADING_STATE,
    payload,
});

export const setMeLoadingStatus = (payload) => ({
    type: types.SET_ME_LOADING_STATE,
    payload,
});

export const fetchUsers = () => ({
    type: types.FETCH_USERS,
});

export const setUsers = (payload) => ({
    type: types.SET_USERS,
    payload,
});

export const updateUserRequest = payload => ({
    type: types.UPDATE_USER_REQUEST,
    payload
});

export const updateUserAction = payload => ({
    type: types.UPDATE_USER_ACTION,
    payload
});

export const searchUser = payload => ({
    type: types.SEARCH_USER,
    payload
});

export const createUser = payload => ({
    type: types.CREATE_USER,
    payload
});

export const pushUser = payload => ({
    type: types.PUSH_USER,
    payload
});

export const deleteUser = payload => ({
    type: types.DELETE_USER,
    payload
});

export const fetchToken = (payload) => ({
    type: types.FETCH_TOKEN,
    payload
});

export const setToken = (payload) => ({
    type: types.SET_TOKEN,
    payload,
});

export const getMe = () => ({
    type: types.FETCH_ME
});

export const setMe = (payload) => ({
    type: types.SET_ME,
    payload,
});

export const updateMe = payload => ({
    type: types.UPDATE_ME,
    payload
});
export const logout = () => ({
    type: types.SET_LOGOUT,
})