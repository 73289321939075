import React from "react";
import {Button, Input, Modal, Select, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";

import BookSidebar from "components/BookSidebar";
import Header from "components/Header";
import Layout from "components/Layout";
import BookCardItem from "components/BookCardItem";
import BookDetail from "components/BookDetail";
import Preloader from "components/Preloader";
import Confirm from "components/Confirm";
import BookForm from "components/BookForm";

import {selectMe} from "store/ducks/users/selectors";
import {
    deleteBook,
    fetchBooks,
    filterByCategory,
    fromFavouriteAction,
    searchBook,
    toFavouriteAction,
    toggleNewBooks,
    getBookAction,
    returnBookAction
} from "store/ducks/books/actions";

import {selectBooksState, selectLoadingStatus} from "store/ducks/books/selectors";
import {selectCategoriesItems} from "store/ducks/catagories/selectors";
import {fetchCategories} from "store/ducks/catagories/actions";

import SearchIcon from "assets/img/search.svg";
import AddIcon from "assets/img/add.svg";

import "./styles.scss";


export default function Books() {

    const dispatch = useDispatch();
    const books = useSelector(selectBooksState);
    const categories = useSelector(selectCategoriesItems);
    const isLoading = useSelector(selectLoadingStatus);
    const user = useSelector(selectMe);

    const [filteredBooks, setFilteredBooks] = React.useState([])
    const [selector, setSelector] = React.useState("all")

    React.useEffect(() => {
        dispatch(fetchBooks(user));
        dispatch(fetchCategories());


    }, [, dispatch]);


    React.useEffect(() => {
        setSelector("all")
        setFilteredBooks(books.items)

    }, [, books]);


    const [visible, setVisible] = React.useState(false);
    const [editBookItem, setEditBookItem] = React.useState({});
    const [search, setSearch] = React.useState("");
    const [confirmDelete, setConfirmDelete] = React.useState(0);
    const [modalTitle, setModalTitle] = React.useState("Добавить книгу");


    const [book, setBook] = React.useState({
        favourite: false,
        title: { rendered: "" },
        content: { rendered: "" },
        acf: { writer: "", size: "", new: true, img: { url: "" } }
    });
    function openModal(book) {
        setBook(book);
        setVisible(true);
    }
    const toggleFavourite = (book) => {
        if (book.favourite) {
            dispatch(fromFavouriteAction({book: book.id, user: user.id}))
        } else {
            dispatch(toFavouriteAction({book: book.id, user: user.id} ))
        }
    }

    const toggleReading = (book) => {
        if (book.reading) {
            dispatch(returnBookAction({book: book.id, user: user.id}))
        } else {
            dispatch(getBookAction({book: book.id, user: user.id} ))
        }
    }


    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const showModal = (type, item) => {
        if (type === "create") {
            setModalTitle("Добавить книгу");
            setEditBookItem({})
        } else {
            setModalTitle("Редактировать книгу")
            setEditBookItem(item)
        }
        setIsModalVisible(true);
    };
    const hideModalForm = () => {
        setEditBookItem({})
        setIsModalVisible(false);
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSearch = (e) =>{
        setSearch(e);
        dispatch(searchBook(e));
    };

    const filterNews = () => {
        setSearch("");
        dispatch(toggleNewBooks());
    }
    const filterCategory = title => {
        setSearch("");
        dispatch(filterByCategory(title));
        setVisible(false);
    }

    const onCloseConfirmDelete = () =>{
        setConfirmDelete(0);
    };

    const onSubmitDelete = () => {
        dispatch(deleteBook(confirmDelete));
        setConfirmDelete(0);
    };
    const handleSelect = (value) => {

        if (value === "all") {
            setFilteredBooks(books.items)
        }
        if (value === "favourite") {
            let newArray = books.items.filter(item => item.favourite && item.favourite === true)
            setFilteredBooks(newArray)
        }
        if (value === "readed") {
            let newArray = books.items.filter(item => item.readed && item.readed === true)
            setFilteredBooks(newArray)
        }
        if (value === "reading") {
            let newArray = books.items.filter(item => item.reading && item.reading === true)
            setFilteredBooks(newArray)
        }
        setSelector(value)
    }


    return (
        <Layout>
            <Header>
                <Input placeholder="Поиск по автору/названию" value={search} onChange={e=> handleSearch(e.target.value)} className="custom" prefix={<SearchIcon />} />
            </Header>
            <div className="container">
                <div className="book-page-wrapper">
                    <BookSidebar categories={categories} filterNews={filterNews} filterCategory={filterCategory} />
                    <div className="book-page-content">
                        <div className="page-title-wrapper">
                            <h2>Список книг</h2>
                            {
                                Object.keys(user).length > 0 && user.user_detail.roles[0] === "administrator" ? (
                                    <Button type="text" icon={<AddIcon/>} onClick={() => showModal("create")}>
                                        Добавить книгу
                                    </Button>
                                ) : null
                            }
                        </div>
                        <div className="books-page-filters">
                            <div className="books-page-filters__info">
                                <strong>Всего:</strong> {books.defaultItems.length} книг
                            </div>
                            <div className="books-page-filters__actions">
                                <Select value={selector} style={{ width: 160 }} onChange={handleSelect}>
                                    <Select.Option value="all">Все книги</Select.Option>
                                    <Select.Option value="favourite">Избранное</Select.Option>
                                    <Select.Option value="readed">Прочитанное</Select.Option>
                                    <Select.Option value="reading">Читаю</Select.Option>
                                </Select>

                            </div>
                        </div>
                        <div className="books-grid">
                                {
                                    isLoading === "LOADING" ? <Preloader /> :
                                        isLoading === "LOADED" ?
                                           filteredBooks.length > 0 ?
                                                <Row gutter={30}>
                                                    {
                                                        filteredBooks.map(item => (
                                                            <BookCardItem
                                                                onEdit={() => showModal("edit", item)}
                                                                key={item.id}
                                                              openModal={() => openModal(item)}
                                                              book={item}
                                                              user={user}
                                                                toggleFavourite={toggleFavourite}
                                                                toggleReading={toggleReading}
                                                            onDelete={()=>setConfirmDelete(item.id)}
                                                            />
                                                        ))
                                                    }
                                                </Row>
                                                : null
                                            :null
                                }

                        </div>
                    </div>
                </div>
            </div>

            <Modal title={modalTitle} centered visible={isModalVisible} onCancel={handleCancel} footer={null} width={1000}>
                <BookForm editBookItem={editBookItem} categories={categories} hideModalForm={hideModalForm}/>
            </Modal>
            <BookDetail visible={visible} book={book} filterCategory={filterCategory} toggleFavourite={toggleFavourite} toggleReading={toggleReading} closeModal={() => setVisible(false)}/>

            <Confirm
                confirmDeleteText="Удалить книгу?"
                confirmDelete={confirmDelete}
                onCloseConfirmDelete={onCloseConfirmDelete}
                onSubmitDelete={onSubmitDelete}
            />
        </Layout>

    )
}