import {call, all, put, takeLatest} from 'redux-saga/effects';
import types from "./types";
import {pushBookAction, setBooks, setBooksLoadingStatus} from "./actions";

import Api from "core/api";
import {updateBookAction} from "store/ducks/books/actions";
import {notification} from "antd";

function* getAllBooks({payload}) {
    yield put(setBooksLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.books.getAllBooks);
        yield put(setBooks({data: response.data, user: payload}));
        yield put(setBooksLoadingStatus("LOADED"))

    } catch (error) {
        console.log(error)
        yield put(setBooksLoadingStatus("ERROR"))
    }
}

function* getAllBooksFlow() {
    yield takeLatest(types.FETCH_BOOKS, getAllBooks)
}

function* createBook({payload}) {

    yield put(setBooksLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.books.createBook, payload);
        yield put(pushBookAction(response.data));
        yield put(setBooksLoadingStatus("LOADED"))

        notification.success({
            message: 'Книга добавлена'
        });

    } catch (error) {
        console.log(error)
        yield put(setBooksLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка при добавлении книги'
        });
    }
}

function* createBookFlow() {
    yield takeLatest(types.CREATE_BOOK, createBook)
}


function* updateBook({payload}) {

    yield put(setBooksLoadingStatus("LOADING"))
    try {
        const response2 = yield call(Api.books.usersBookApi, payload);
        const response = yield call(Api.books.updateBook, payload);
        yield put(updateBookAction(response.data));
        yield put(setBooksLoadingStatus("LOADED"))
        notification.success({
            message: 'Книга обновлена'
        });

    } catch (error) {
        console.log(error)
        yield put(setBooksLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка при обновлении книги'
        });
    }
}

function* updateBookFlow() {
    yield takeLatest(types.UPDATE_BOOK_REQUEST, updateBook)
}


function* deleteBook(action) {
    yield put(setBooksLoadingStatus("LOADING"))
    try {
        yield call(Api.books.deleteBookApi, action.payload);
        yield put(setBooksLoadingStatus("LOADED"))
        notification.success({
            message: 'Книга удалена'
        });

    } catch (error) {
        console.log(error)
        yield put(setBooksLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка при удалении книги'
        });
    }
}

function* deleteBookFlow() {
    yield takeLatest(types.DELETE_BOOK, deleteBook)
}


function* toFavouriteSaga({payload}) {
    try {
        yield call(Api.books.addToFavourite, payload);
    } catch (error) {
        console.log(error)
    }
}

function* toFavouriteSagaFlow() {
    yield takeLatest(types.TO_FAVOURITE, toFavouriteSaga)
}

function* fromFavouriteSaga({payload}) {
    try {
        yield call(Api.books.removeFromFavourite, payload);
    } catch (error) {
        console.log(error)
    }
}

function* fromFavouriteSagaFlow() {
    yield takeLatest(types.FROM_FAVOURITE, fromFavouriteSaga)
}


function* getBookSaga({payload}) {
    try {
        yield call(Api.books.getBookApi, payload);
    } catch (error) {
        console.log(error)
    }
}

function* getBookSagaFlow() {
    yield takeLatest(types.GET_BOOK, getBookSaga)
}


function* returnBookSaga({payload}) {
    try {
        yield call(Api.books.returnBookApi, payload);
    } catch (error) {
        console.log(error)
    }
}

function* returnBookSagaFlow() {
    yield takeLatest(types.RETURN_BOOK, returnBookSaga)
}

export default function* booksSaga() {
    yield all([
        getAllBooksFlow(),
        createBookFlow(),
        updateBookFlow(),
        deleteBookFlow(),
        toFavouriteSagaFlow(),
        fromFavouriteSagaFlow(),
        getBookSagaFlow(),
        returnBookSagaFlow(),
    ])
}