import {call, all, put, takeLatest} from 'redux-saga/effects';
import types from "./types";
import { setCategories, setCategoriesLoadingStatus, pushCategory, updateCategoryAction } from "./actions";

import Api from "core/api";
import {notification} from "antd";

function* getAllCategories() {

    yield put(setCategoriesLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.categories.getAllCategories);
        yield put(setCategories(response.data));
        yield put(setCategoriesLoadingStatus("LOADED"))

    } catch (error) {
        console.log(error)
        yield put(setCategoriesLoadingStatus("ERROR"))
    }
}

function* getAllCategoriesFlow() {
    yield takeLatest(types.FETCH_CATEGORIES, getAllCategories)
}


function* createCategory(action) {
    console.log('create')
    yield put(setCategoriesLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.categories.createCategory, action.payload);

        yield put(pushCategory(response.data));
        yield put(setCategoriesLoadingStatus("LOADED"))
        notification.success({
            message: 'Категория создана'
        });
    } catch (error) {
        console.log(error)
        yield put(setCategoriesLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка создания категории'
        });
    }
}

function* createCategoryFlow() {
    yield takeLatest(types.CREATE_CATEGORY, createCategory)
}


function* updateCategorySaga(action) {
    console.log('sagffffffa')
    yield put(setCategoriesLoadingStatus("LOADING"))
    try {
        const response = yield call(Api.categories.updateCategoryApi, action.payload);
        yield put(updateCategoryAction(response.data));
        yield put(setCategoriesLoadingStatus("LOADED"))
        notification.success({
            message: 'Категория обновлена'
        });

    } catch (error) {
        console.log(error)
        yield put(setCategoriesLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка обновления категории'
        });
    }
}

function* updateCategoryFlow() {
    yield takeLatest(types.UPDATE_CATEGORY_REQUEST, updateCategorySaga)
}


function* deleteCategory(action) {
    yield put(setCategoriesLoadingStatus("LOADING"))
    try {
        yield call(Api.categories.deleteCategoryApi, action.payload);

        yield put(setCategoriesLoadingStatus("LOADED"))
        notification.success({
            message: 'Категория удалена'
        });
    } catch (error) {
        console.log(error)
        yield put(setCategoriesLoadingStatus("ERROR"))
        notification.error({
            message: 'Ошибка удаления категории'
        });
    }
}

function* deleteCategoryFlow() {
    yield takeLatest(types.DELETE_CATEGORY, deleteCategory)
}

export default function* categoriesSaga() {
    yield all([
        getAllCategoriesFlow(),
        createCategoryFlow(),
        updateCategoryFlow(),
        deleteCategoryFlow(),
    ])
}