import React from "react";
import ImgCrop from "antd-img-crop";
import {Editor} from "@tinymce/tinymce-react";
import {Button, Switch, Col, Form, Input, notification, Row, Select, Upload, InputNumber} from "antd";
import {useDispatch, useSelector} from "react-redux";

import Api from "core/api";
import {getSlug} from "core/utils";
import {createBookAction, updateBookRequest} from "store/ducks/books/actions";
import {selectUsersItems, setUsersLoadingStatus} from "store/ducks/users/selectors";
import {fetchUsers} from "store/ducks/users/actions";

import Pdf from "assets/img/pdf.svg";

export default function BookForm({editBookItem, categories, hideModalForm}) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const users = useSelector(selectUsersItems);
    const isLoading = useSelector(setUsersLoadingStatus);

    React.useEffect(() => {
        dispatch(fetchUsers());
    }, [,dispatch]);

    React.useEffect(() => {
        if (Object.keys(editBookItem).length > 0) {
            form.setFieldsValue( {
                id: editBookItem.id,
                title: editBookItem.title.rendered,
                slug: editBookItem.slug,
                new: editBookItem.acf.new,
                writer: editBookItem.acf.writer,
                size: editBookItem.acf.size,
                count: editBookItem.acf.count,
                category: editBookItem.acf?.category[0]?.ID || null,
            })
            setText(editBookItem.content.rendered)
            setImage(editBookItem.acf.img)
            setPdf(editBookItem.acf.book_pdf)
            if (editBookItem.acf.now_read) {
                let newArray = []
                editBookItem.acf.now_read.map(
                    item => newArray.push(item.ID)
                )
                setUsersForm(newArray)
            }

        } else {
            form.setFieldsValue( {
                id: 0,
                title: "",
                slug: "",
                new: false,
                writer: "",
                size: "",
                count: 1,
                category: "",
            })
            setText("");
            setImage(null);
            setPdf(null);
            setUsersForm([]);
        }
    }, [, editBookItem]);

    const [image, setImage] = React.useState(null);
    const [pdf, setPdf] = React.useState(null);
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [loadingPdf, setLoadingPdf] = React.useState(false);

    const [text, setText] = React.useState("");
    const [usersForm, setUsersForm] = React.useState([]);

    const uploadPdfButton = (
        <div>
            {loadingPdf ? "Загрузка" : null}
            <div style={{ marginTop: 8 }}>Выбрать pdf</div>
        </div>
    );

    const uploadImageButton = (
        <div>
            {loadingImage ? "Загрузка" : null}
            <div style={{ marginTop: 8 }}>Выбрать картинку</div>
        </div>
    );

    const uploadImage = async (params) => {
        setLoadingImage(true)
        try {
            const res = await Api.files.uploadFile(params.file)
            setImage(res.data)
            notification.success({
                message: 'Изображение загружено'
            });
            setLoadingImage(false)

        } catch (err) {
            notification.error({
                message: 'Изображение не загружено'
            });
            setLoadingImage(false)
        }
    };

    const uploadPdf = async (params) => {
        setLoadingPdf(true)
        try {
            const res = await Api.files.uploadFile(params.file)
            setPdf(res.data)
            notification.success({
                message: 'PDF загружен'
            });
            setLoadingPdf(false)

        } catch (err) {
            notification.error({
                message: 'PDF не загружен'
            });
            setLoadingPdf(false)
        }
    };


    const onPreview = async image => {
        let src = image.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(image.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const img = new Image();
        img.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(img.outerHTML);
    };

    const generateSlug = (event) => {
        form.setFieldsValue( {
            title: event.target.value,
            slug: getSlug(event.target.value, {lang: 'ru'})
        });
    };

    const onFinish = (values) => {
        const fields = {...values, img: image, pdf: pdf, content: text, users: usersForm};
        if (values.id === 0) {
            dispatch(createBookAction(fields));
        } else {
            dispatch(updateBookRequest(fields));
        }
        hideModalForm();
    };
    function handleChange(value) {
        setUsersForm(value)
    }
    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
        >
            <Form.Item
                label="Id"
                name="id"
                hidden
            >
                <Input/>
            </Form.Item>

            <Row gutter={16}>

                <Col span={12}>
                    <Form.Item
                        label="Название"
                        name="title"
                        rules={[ {required: true,message: 'Укажите название!',},]}
                    >
                        <Input onChange={event => generateSlug(event)}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Код"
                        name="slug"
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Автор"
                        name="writer"
                        rules={[ {required: true,message: 'Укажите автора!',},]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Объем"
                        name="size"
                        rules={[ {required: true,message: 'Уажите объем!',},]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="new" label="Новинка">
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="count" label="Количество">
                        <InputNumber min={1} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="category"
                        label="Категория"
                        rules={[{ required: true, message: 'Укажите категорию!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Выберите категорию"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {categories
                                ? categories.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.title.rendered}</Select.Option>

                                ))
                                : null
                            }
                        </Select>
                    </Form.Item>

                </Col>
                {
                    isLoading === "LOADED" ?
                        <Col span={24}>
                            <Select
                                value={usersForm}
                                mode="multiple"
                                placeholder="Выберите пользователей"
                                onChange={handleChange}
                                style={{ width: '100%', marginBottom: "40px", borderRadius: "8px" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {users
                                    ? users.map(item => (
                                        <Select.Option key={item.id} value={item.id}>{item.display_name || item.name}</Select.Option>
                                    ))
                                    : null
                                }

                            </Select>
                        </Col>
                        : null
                }

                <Col span={24}>

                    <Editor
                        tinymceScriptSrc={"https://books.crmgroup.ru/tinymce/tinymce.min.js"}

                        value={text}
                        onEditorChange={(newText) => setText(newText)}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | code | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                </Col>
            </Row>
            <div className="book-modal-upload">
                <ImgCrop rotate aspect={415/585}>
                    <Upload
                        showUploadList={false}
                        listType="picture-card"
                        customRequest={uploadImage}
                        onPreview={onPreview}
                    >
                        {image ? <img src={image.source_url || image.url} alt="avatar" style={{ width: '100%' }} /> : uploadImageButton}
                    </Upload>
                </ImgCrop>
                <Upload
                    showUploadList={false}
                    listType="picture-card"
                    customRequest={uploadPdf}
                >
                    {pdf ? <Pdf /> : uploadPdfButton}
                </Upload>
            </div>




            <div className="button-wrapper">
                <Form.Item>
                    <Button htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
}