const VISIBLE_FORM = 'users/VISIBLE_FORM';
const FETCH_USERS = 'users/FETCH_USERS';
const SET_USERS = 'users/SET_USERS';
const CREATE_USER = 'users/CREATE_USER';
const PUSH_USER = 'users/PUSH_USER';
const UPDATE_USER_REQUEST = 'users/UPDATE_USER_REQUEST';
const UPDATE_USER_ACTION = 'users/UPDATE_USER_ACTION';
const SEARCH_USER = 'users/SEARCH_USER';
const DELETE_USER = 'users/DELETE_USER';
const SET_USER_LOADING_STATE = 'users/SET_USER_LOADING_STATE';
const SET_ME_LOADING_STATE = 'users/SET_ME_LOADING_STATE';
const FETCH_TOKEN = 'users/FETCH_TOKEN';
const SET_TOKEN = 'users/SET_TOKEN';
const FETCH_ME = 'users/FETCH_ME';
const SET_ME = 'users/SET_ME';
const UPDATE_ME = 'users/UPDATE_ME';
const SET_LOGOUT = 'users/SET_LOGOUT';

export default {
    VISIBLE_FORM,
    FETCH_USERS,
    SET_USERS,
    CREATE_USER,
    PUSH_USER,
    UPDATE_USER_REQUEST,
    UPDATE_USER_ACTION,
    SEARCH_USER,
    DELETE_USER,
    SET_USER_LOADING_STATE,
    SET_ME_LOADING_STATE,
    FETCH_TOKEN,
    SET_TOKEN,
    FETCH_ME,
    SET_ME,
    UPDATE_ME,
    SET_LOGOUT,
};