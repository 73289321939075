import React from "react";

import "./styles.scss";

export default function Preloader() {
    return (
        <div className="preloader">
            <div className="lds-dual-ring" />
        </div>
    )
}