const SET_BOOKS = 'books/SET_BOOKS';
const FETCH_BOOKS = 'books/FETCH_BOOKS';
const SET_LOADING_STATE = 'books/SET_LOADING_STATE';
const SEARCH_BOOK = 'books/SEARCH_BOOK';
const TOGGLE_NEW = 'books/TOGGLE_NEW';
const FILTER_BY_CATEGORY = 'books/FILTER_BY_CATEGORY';
const CREATE_BOOK = 'books/CREATE_BOOK';
const PUSH_BOOK = 'books/PUSH_BOOK';
const DELETE_BOOK = 'books/DELETE_BOOK';
const UPDATE_BOOK_ACTION = 'books/UPDATE_BOOK_ACTION';
const UPDATE_BOOK_REQUEST = 'books/UPDATE_BOOK_REQUEST';
const TO_FAVOURITE = 'books/TO_FAVOURITE';
const FROM_FAVOURITE = 'books/FROM_FAVOURITE';
const GET_BOOK = 'books/GET_BOOK';
const RETURN_BOOK = 'books/RETURN_BOOK';

export default {
    SET_BOOKS,
    FETCH_BOOKS,
    SET_LOADING_STATE,
    SEARCH_BOOK,
    TOGGLE_NEW,
    FILTER_BY_CATEGORY,
    CREATE_BOOK,
    PUSH_BOOK,
    DELETE_BOOK,
    UPDATE_BOOK_ACTION,
    UPDATE_BOOK_REQUEST,
    TO_FAVOURITE,
    FROM_FAVOURITE,
    GET_BOOK,
    RETURN_BOOK,
};