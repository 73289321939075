import axiosInstance from "../axios";

export default {
    getAllBooks: () => axiosInstance.get(`/wp/v2/books?orderby=id&order=desc&s976_per_page=1000`),
    deleteBookApi: id => axiosInstance.delete(`/wp/v2/books/${id}`),
    createBook: bookData => {
        let formData = new FormData();
        formData.append("title", bookData.title)
        formData.append("slug", bookData.slug)
        formData.append("status", "publish")
        formData.append("content", bookData.content)
        formData.append("fields[writer]", bookData.writer)
        formData.append("fields[size]", bookData.size)
        if (!bookData.new) {
            formData.append("fields[new]", "")
        } else {
            formData.append("fields[new]", bookData.new)
        }
        formData.append("fields[category]", bookData.category)
        formData.append("fields[count]", bookData.count)

        if (bookData.img) {
            formData.append("fields[img]", bookData.img.id)
        }

        return axiosInstance.post(`/wp/v2/books`, formData)
    },

    updateBook: bookData => {
        let formData = new FormData();
        formData.append("title", bookData.title)
        formData.append("slug", bookData.slug)
        formData.append("status", "publish")
        formData.append("content", bookData.content)
        formData.append("fields[writer]", bookData.writer)
        formData.append("fields[size]", bookData.size)
        formData.append("fields[count]", bookData.count)


        formData.append("fields[category]", bookData.category)
        if (!bookData.new) {
            formData.append("fields[new]", "")
        } else {
            formData.append("fields[new]", bookData.new)
        }
        if (bookData.img) {
            formData.append("fields[img]", bookData.img.id)
        }
        if (bookData.pdf) {
            formData.append("fields[book_pdf]", bookData.pdf.id)
        }

        return axiosInstance.post(`/wp/v2/books/${bookData.id}`, formData)
    },

    addToFavourite: bookData => {
        const data = {
            user: bookData.user,
            book: bookData.book,
        }
        return axiosInstance.post(`/books/v1/add-favourite`, data)
    },

    removeFromFavourite: bookData => {
        const data = {
            user: bookData.user,
            book: bookData.book,
        }
        return axiosInstance.post(`/books/v1/remove-favourite`, data)
    },

    getBookApi: bookData => {
        const data = {
            user: bookData.user,
            book: bookData.book,
        }
        return axiosInstance.post(`/books/v1/get-read`, data)
    },

    returnBookApi: bookData => {
        const data = {
            user: bookData.user,
            book: bookData.book,
        }
        return axiosInstance.post(`/books/v1/return-book`, data)
    },
    usersBookApi: bookData => {
        console.log(bookData)
        const data = {
            users: bookData.users.toString(),
            book: bookData.id,
        }
        return axiosInstance.post(`/books/v1/book-users`, data)
    }



}
