import axiosInstance from "../axios";

export default {
    signIn: postData => axiosInstance.post(`/jwt-auth/v1/token`, {
        username: postData.email,
        password: postData.password,
    }),
    getMeApi: () => axiosInstance.get(`/wp/v2/users/me`),
    updateMeApi: userData => {

        const data = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email
        }
        if (userData.password !== "") {
            data['password'] = userData.password;
        }

        axiosInstance.post(`/wp/v2/users/me`, data)
    },
}


