import React from "react";

import "./styles.scss";

export default function TableItemWrapper({children}) {
    return (
        <div className="table-row">
            {children}
        </div>
    )
}