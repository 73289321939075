import React from "react";
import {Modal, Tooltip} from 'antd';
import { saveAs } from "file-saver";

import "./styles.scss";
import CartIcon from "assets/img/cart.svg";
import FavouriteIcon from "assets/img/favourite.svg";
import Download from "assets/img/download.svg";

export default function BookDetail({visible, book, closeModal, toggleFavourite, toggleReading, filterCategory}) {

    const saveFile = () => {
        saveAs(`${book.acf.book_pdf}`, "");
    };

    return (
        <Modal
            centered
            visible={visible}
            onCancel={() => closeModal()}
            width={1000}
            footer={null}
        >
             <div className="book-detail-wrapper">
                 {book.acf.img ?<img src={book.acf.img.url} alt="" className="book-detail-img"/> : null }
                    <div className="book-detail-content">
                        <h4 className="book-detail-title" dangerouslySetInnerHTML={{__html: book.title.rendered}} />

                        <div className="book-detail-author">
                            Автор: {book.acf.writer}
                        </div>
                        <p><strong>Аннотация: </strong> <span dangerouslySetInnerHTML={{__html: book.content.rendered}} /> </p>
                        <div className="book-detail-size"><strong>Объем: </strong>{book.acf.size}</div>
                        <div className="book-detail-category-wrapper">

                            { book.acf.category ?
                                <div className="book-detail-category-item" onClick={() => filterCategory(book.acf.category[0].post_title)}>
                                    {book.acf.category[0].post_title}
                                </div>
                                :null
                            }

                        </div>
                        { book.acf.now_read && book.acf.now_read.length > 0 ? <div className="book-card-item__used">
                                <strong>Читают:</strong> {book.acf.now_read.map(item => (
                                <span key={item.ID}>{item.user_firstname}&nbsp;{item.user_lastname}</span>
                            ))}
                            </div>
                            : null
                        }
                        <div className="book-detail-actions">
                            <Tooltip placement="top" title={book.reading ? "Вернуть книгу" : "Взять почитать"}>
                                <button className={book.reading ? "selected btn-cart" : "btn-cart"} disabled={!book.reading && book.acf.now_read && book.acf.now_read.length >= book.acf.count} onClick={() => toggleReading(book)}><CartIcon /></button>
                            </Tooltip>
                            <Tooltip placement="top" title={book.favourite ? "Удалить из избранного" : "В избранное"}>
                                <button className={book.favourite ? "btn-favourite selected" : "btn-favourite"} onClick={() =>toggleFavourite(book)}><FavouriteIcon /></button>
                            </Tooltip>
                            {
                                book.acf.book_pdf ?
                                    <Tooltip placement="top" title="Скачать">
                                        <button className="btn-favourite" onClick={() => saveFile()}><Download /></button>
                                    </Tooltip>
                                    : null
                            }

                        </div>
                    </div>
             </div>

        </Modal>
    )
}