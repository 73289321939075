import types from "./types";

export const setCategoriesLoadingStatus = (payload) => ({
    type: types.SET_LOADING_STATE,
    payload,
});

export const setCategories = (payload) => ({
    type: types.SET_CATEGORIES,
    payload,
});

export const fetchCategories = () => ({
    type: types.FETCH_CATEGORIES,
});

export const updateCategoryAction = payload => ({
    type: types.UPDATE_CATEGORY_ACTION,
    payload
});

export const updateCategoryRequest = payload => ({
    type: types.UPDATE_CATEGORY_REQUEST,
    payload
});

export const createCategory = payload => ({
    type: types.CREATE_CATEGORY,
    payload
});

export const pushCategory = payload => ({
    type: types.PUSH_CATEGORY,
    payload
});

export const searchCategory = payload => ({
    type: types.SEARCH_CATEGORY,
    payload
});

export const deleteCategory = payload => ({
    type: types.DELETE_CATEGORY,
    payload
});
