import axiosInstance from "../axios";

export default {
    getAllCategories: () => axiosInstance.get(`/wp/v2/categories?page=1&s976_per_page=1000&orderby=id`),
    // getItemApi: params => axiosInstance.get(`/catalog/products/${params.id}/`),
    deleteCategoryApi: id => axiosInstance.delete(`/wp/v2/categories/${id}`),
    updateCategoryApi: categoryData => axiosInstance.put(`/wp/v2/categories/${categoryData.id}`, {
        title: categoryData.title,
        slug: categoryData.slug,
        status: "publish"
    }),
    createCategory: categoryData => axiosInstance.post(`/wp/v2/categories`, {
        title: categoryData.title,
        slug: categoryData.slug,
        status: "publish"
    }),
}
