import axios from 'axios';

const baseURL = 'https://api.books.crmgroup.ru/wp-json';

// axios.interceptors.request.use((config) => {
//     config.headers['token'] = window.localStorage.getItem('token');
//     return config;
// });

const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
}
const access_token = window.localStorage.getItem('token');
if (access_token) {
  headers['Authorization'] = `Bearer ${access_token}`;
}

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers
});

export default axiosInstance;