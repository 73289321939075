import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Drawer, Form, Input, Select} from "antd";

import {createUser, updateMe, updateUserRequest} from "store/ducks/users/actions";
import {getSlug} from "core/utils";
import {selectMe} from "store/ducks/users/selectors";


export default function UserForm({visible, user, onClose}) {
    const me = useSelector(selectMe);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (user) {
            form.setFieldsValue( {
                id: user.id,
                username: user.user_detail.username,
                first_name: user.user_detail.first,
                last_name: user.user_detail.last,
                email: user.user_detail.email,
                password: "",
                role: user.user_detail.roles[0]
            });
        } else {
            form.setFieldsValue( {
                id: 0,
                username: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                role: ""
            });
        }
    }, [, user]);



    const onFinish = (values) => {
        console.log('Success:', values);

        if (values.id === 0) {
            console.log(1)
            dispatch(createUser(values));
            form.setFieldsValue( {
                id: 0,
                username: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                role: ""
            });
        } else {
            console.log(2)
            if (values.id === me.id) {
                console.log(3)
                dispatch(updateMe(values));
            } else {
                console.log('4')
                dispatch(updateUserRequest(values));
                form.setFieldsValue( {
                    id: 0,
                    username: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    role: ""
                });
            }
        }

        onClose()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const generateSlug = (event, type) => {
        if (type === "first") {
            form.setFieldsValue( {
                first_name: event.target.value,
                username: getSlug(event.target.value + form.getFieldsValue().last_name, {lang: 'ru'})
            })
        }
        if (type === "last") {
            form.setFieldsValue( {
                last_name: event.target.value,
                username: getSlug(form.getFieldsValue().first_name + event.target.value, {lang: 'ru'})
            })
        }
    };

    return (
        <Drawer
            width={640}
            placement="right"
            closable={false}
            onClose={() =>onClose()}
            visible={visible}
        >
            <h3 className="ant-drawer-body-title">
                Редактировать профиль
            </h3>
            <div className="ant-drawer-body-form">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        role: "contributor"
                    }}
                >
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Юзернейм" name="username">
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="Имя" name="first_name">
                        <Input onChange={event => generateSlug(event, "first")} />
                    </Form.Item>
                    <Form.Item label="Фамилия" name="last_name">
                        <Input onChange={event => generateSlug(event, "last")} />
                    </Form.Item>
                    <Form.Item
                        label="Емейл"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Укажите корректный емейл!',
                            },
                            {
                                required: true,
                                message: 'Уажите емейл!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {
                        user && user.id !== me.id ?
                            <Form.Item name="role" label="Роль" rules={[{ required: true, message: 'Выберите роль пользователя!', }]}>
                                <Select
                                    placeholder="Выберите роль пользователя"
                                >
                                    <Select.Option value="contributor">Пользователь</Select.Option>
                                    <Select.Option value="administrator">Админ</Select.Option>
                                </Select>
                            </Form.Item>
                            : null
                    }

                    <Form.Item
                        label="Пароль"
                        name="password"
                    >
                        <Input.Password />
                    </Form.Item>

                    <div className="button-wrapper">
                        <Form.Item>
                            <Button htmlType="submit">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Drawer>
    )
}