import { all } from 'redux-saga/effects';
import categoriesSaga from './ducks/catagories/sagas';
import usersSaga from './ducks/users/sagas';
import booksSaga from './ducks/books/sagas';

export default function* rootSaga() {
    yield all([
        categoriesSaga(),
        usersSaga(),
        booksSaga(),
    ]);
}
