import React from "react";
import { Form, Input, Button } from 'antd';
import {useDispatch, useSelector} from 'react-redux';

import "./styles.scss";

import LoginIcon from "assets/img/login.svg";
import {fetchToken} from "store/ducks/users/actions";
import {useHistory} from "react-router-dom";
import {selectUsersState} from "store/ducks/users/selectors";

export default function Login() {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectUsersState);

    React.useEffect(() => {
        if (auth.meLoadingStatus === "LOADED") {
            history.push('/')
        }

    }, [, auth]);


    const onFinish = (values) => {
        dispatch(fetchToken(values));
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-layout">
            <div className="login-form-wrapper">
                <div className="login-form-content">
                    <h3 className="login-form-title">
                        Войдите в свой аккаунт
                    </h3>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                    >
                        <Form.Item
                            label="Емейл"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Укажите корректный емейл!',
                                },
                                {
                                    required: true,
                                    message: 'Уажите емейл!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Укажите пароль!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType="submit">
                                Войти
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="login-form-img">
                <LoginIcon />
            </div>
        </div>
    )
}