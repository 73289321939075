import axiosInstance from "../axios";

export default {
    getAllUsers: () => axiosInstance.get(`/wp/v2/users?orderby=name&order=asc&s976_per_page=1000`),
    deleteUserApi: id => axiosInstance.delete(`/wp/v2/users/${id}`, {
        data: {
            reassign: 1,
            force: true
        }
    }),
    createUser: userData => axiosInstance.post(`/wp/v2/users`, {
        username: userData.username,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        password: userData.password,
        roles: [userData.role]
    }),
    updateUser: userData => {
        const data = {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            roles: [userData.role]
        }
        if (userData.password !== "") {
            data['password'] = userData.password;
        }
        return axiosInstance.post(`/wp/v2/users/${userData.id}`, data)
    },

    // getItemApi: params => axiosInstance.get(`/catalog/products/${params.id}/`),
    // deleteItemApi: params => axiosInstance.delete(`/catalog/products/${params.id}/`),
    // updateItemApi: params => axiosInstance.put(`/catalog/products/${params.routerId}/`, params.params),
    // createItemApi: params => axiosInstance.post(`/catalog/products/create/`, params),
}
