import produce, {current} from 'immer';
import types from "./types";

const initialCategoriesState = {
    defaultItems: [],
    items: [],
    LoadingStatus: "NEVER"
};

export const categoriesReducer = produce((draft, action) => {
    switch (action.type) {
        case types.SET_CATEGORIES:
            draft.items = action.payload;
            draft.defaultItems = action.payload;
            break;

        case types.FETCH_CATEGORIES:
            draft.items = [];
            break;

        case types.PUSH_CATEGORY:
            draft.items.unshift(action.payload);
            draft.defaultItems.unshift(action.payload);
            break;

        case types.SEARCH_CATEGORY:
            const currentStateData = current(draft)
            if (action.payload !== "") {
                draft.items = currentStateData.defaultItems.filter(item => item.title.rendered.toLowerCase().includes(action.payload));
            } else {
                draft.items = currentStateData.defaultItems
            }

            break;

        case types.DELETE_CATEGORY:
            draft.items = draft.items.filter(item => item.id !== action.payload);
            draft.defaultItems = draft.defaultItems.filter(item => item.id !== action.payload);
            break;

        case types.UPDATE_CATEGORY_ACTION:
            draft.items = draft.items.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            break;

        case types.SET_LOADING_STATE:
            draft.LoadingStatus = action.payload;
            break;

        default:
            break;
    }
}, initialCategoriesState);
