import React from "react";
import { Tooltip } from 'antd';

import "./styles.scss";

import EditIcon from "assets/img/edit.svg";
import CartIcon from "assets/img/cart.svg";
import ToggleIcon from "assets/img/toggle.svg";
import FavouriteIcon from "assets/img/favourite.svg";
import TrashIcon from "assets/img/trash.svg";

export default function BookCardItem({book, user, openModal, onDelete, onEdit, toggleFavourite, toggleReading }) {

    return (
        <div className="book-card-item">
            { book.acf.new ?
            <div className="book-card-item__new">
                Новинка
            </div>
            : null }
            {
                book.acf.img ?
                    <img src={book.acf.img.url} alt="" className="book-card-item__img" onClick={() => openModal()}/>
                    : null
            }

            <div className="book-card-item__title">
                <div className="book-card-item__count">
                    {book.acf.count ? book.acf.count : "-"}
                </div>
                <span dangerouslySetInnerHTML={{__html: book.title.rendered}} />
            </div>
            <div className="book-card-item__author">
                Автор: {book.acf.writer}
            </div>
            { book.acf.now_read && book.acf.now_read.length > 0 ? <div className="book-card-item__used">
                     <strong>Читают:</strong> {book.acf.now_read.map(item => (
                         <span key={item.ID}>{item.user_firstname}&nbsp;{item.user_lastname}</span>
                ))}
                    </div>
            : null
            }
            <div className="book-card-item__actions">
                <Tooltip placement="top" title={book.reading ? "Вернуть книгу" : "Взять почитать"}>
                    <button className={book.reading ? "selected btn-cart" : "btn-cart"} disabled={!book.reading && book.acf.now_read && book.acf.now_read.length >= book.acf.count} onClick={() => toggleReading(book)}><CartIcon /></button>
                </Tooltip>
                <Tooltip placement="top" title="Подробнее">
                    <button className="btn-toggle" onClick={() => openModal()}><ToggleIcon /></button>
                </Tooltip>
                <Tooltip placement="top" title={book.favourite ? "Удалить из избранного" : "В избранное"}>
                    <button className={book.favourite ? "btn-favourite selected" : "btn-favourite"} onClick={() =>toggleFavourite(book)}><FavouriteIcon /></button>
                </Tooltip>
                {
                    Object.keys(user).length > 0 && user.user_detail.roles[0] === "administrator" ? (
                        <>
                            <Tooltip placement="top" title="Редактировать">
                                <button className="btn-edit" onClick={() => onEdit("edit", book)}><EditIcon /></button>
                            </Tooltip>
                            <Tooltip placement="top" title="Удалить">
                                <button className="btn-delete" onClick={onDelete}><TrashIcon /></button>
                            </Tooltip>
                        </>
                    ): null
                }
            </div>
        </div>
    )
}