import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectUsersState} from "store/ducks/users/selectors";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const auth = useSelector(selectUsersState);
    console.log(auth)
    return (
        <Route
            {...rest}
            render={(props) => {
                if (auth.meLoadingStatus === "LOADING") {
                    return <div className="user_loader">
                        <div className="lds-ellipsis">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                        Попытка авторизации..
                    </div>;
                }
                if (auth.meLoadingStatus === "ERROR") {
                    return <Redirect to="/login" />;
                }
                if (auth.meLoadingStatus === "LOGOUT") {
                    return <Redirect to="/login" />;
                }
                if (auth.meLoadingStatus === "LOADED") {
                    return <Component {...props}/>;
                }
            }}
        />
    )
};

export default PrivateRoute;