import React from 'react';

const Confirm = ({ confirmDelete, confirmDeleteText, onCloseConfirmDelete, onSubmitDelete }) => {

    return (

        <div className={confirmDelete !== 0 ? "confirm_dialog is_open" : "confirm_dialog"}>
            <h3>{confirmDeleteText}</h3>
            <div className="button_wrap">
                <button className="cancel_delete" onClick={onCloseConfirmDelete}>
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="35" height="35" rx="17.5" fill="white"/>
                        <path d="M18.8266 17.5002L24.7251 11.6013C25.0916 11.2352 25.0916 10.641 24.7251 10.2749C24.3587 9.90838 23.7652 9.90838 23.3987 10.2749L17.5002 16.1737L11.6013 10.2749C11.2348 9.90838 10.6413 9.90838 10.2749 10.2749C9.90838 10.641 9.90838 11.2352 10.2749 11.6013L16.1737 17.5002L10.2749 23.399C9.90838 23.7651 9.90838 24.3593 10.2749 24.7254C10.4581 24.9084 10.6982 25 10.9381 25C11.1779 25 11.418 24.9084 11.6013 24.7251L17.5002 18.8263L23.3987 24.7251C23.582 24.9084 23.8221 25 24.0619 25C24.3018 25 24.5419 24.9084 24.7251 24.7251C25.0916 24.359 25.0916 23.7648 24.7251 23.3987L18.8266 17.5002Z" stroke="#E42525" strokeWidth="2"/>
                    </svg>
                </button>
                <button className="confirm_delete" onClick={onSubmitDelete} >
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="35" height="35" rx="17.5" fill="white"/>
                        <path d="M26 12L15 23L10 18" stroke="#0ABF30" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>

    )
};

export default Confirm;