import produce, {current} from 'immer';
import types from "./types";
import _ from 'lodash';

const initialUsersState = {
    visibleForm: false,
    defaultItems: [],
    items: [],
    me: {},
    token: "",
    loadingStatus: "LOADING",
    meLoadingStatus: "LOADING"
};

export const usersReducer = produce((draft, action) => {
    switch (action.type) {
        case types.FETCH_TOKEN:
            localStorage.removeItem("token");
            draft.token = "";
            break;

        case types.SET_TOKEN:
            localStorage.setItem("token", action.payload.token)
            draft.token = action.payload.token;
            break;

        case types.SET_ME:
            draft.me = action.payload;
            break;

        case types.VISIBLE_FORM:
            draft.visibleForm = !current(draft).visibleForm;
            break;

        case types.SET_USERS:
            const chars =_.orderBy(action.payload, [user => user.name.toLowerCase()], ['asc']);

            draft.items = chars;
            draft.defaultItems = chars;
            break;

        case types.FETCH_USERS:
            draft.items = [];
            break;

        case types.SEARCH_USER:
            const currentStateData = current(draft)
            if (action.payload !== "") {
                draft.items = currentStateData.defaultItems.filter(item => item.user_detail.last.toLowerCase().includes(action.payload));
            } else {
                draft.items = currentStateData.defaultItems
            }
            break;

        case types.PUSH_USER:
            const newUser = {
                id: action.payload.id,
                user_detail: {
                    username: action.payload.username,
                    email: action.payload.email,
                    first: action.payload.first_name,
                    last: action.payload.last_name,
                    roles: action.payload.roles,

                }
            }
            draft.items.unshift(newUser);
            draft.defaultItems.unshift(newUser);
            break;

        case types.UPDATE_USER_ACTION:
            const updatedUser = {
                id: action.payload.id,
                user_detail: {
                    username: action.payload.username,
                    email: action.payload.email,
                    first: action.payload.first_name,
                    last: action.payload.last_name,
                    roles: action.payload.roles,

                }
            }
            draft.items = draft.items.map(item => {
                if (item.id === updatedUser.id) {
                    return updatedUser;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === updatedUser.id) {
                    return updatedUser;
                }
                return item;
            });
            break;

        case types.DELETE_USER:
            draft.items = draft.items.filter(item => item.id !== action.payload);
            draft.defaultItems = draft.defaultItems.filter(item => item.id !== action.payload);
            break;

        case types.SET_USER_LOADING_STATE:
            draft.loadingStatus = action.payload;
            break;

        case types.SET_ME_LOADING_STATE:
            draft.meLoadingStatus = action.payload;
            break;

        case types.SET_LOGOUT:
            draft.token = "";
            draft.meLoadingStatus = "LOGOUT";
            localStorage.removeItem("token");
            break;
        default:
            break;
    }
}, initialUsersState);
