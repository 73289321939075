const SET_CATEGORIES = 'categories/SET_CATEGORIES';
const FETCH_CATEGORIES = 'categories/FETCH_CATEGORIES';
const CREATE_CATEGORY = 'categories/CREATE_CATEGORY';
const UPDATE_CATEGORY_ACTION = 'categories/UPDATE_CATEGORY_ACTION';
const UPDATE_CATEGORY_REQUEST = 'categories/UPDATE_CATEGORY_REQUEST';
const DELETE_CATEGORY = 'categories/DELETE_CATEGORY';
const PUSH_CATEGORY = 'categories/PUSH_CATEGORY';
const SEARCH_CATEGORY = 'categories/SEARCH_CATEGORY';
const SET_LOADING_STATE = 'categories/SET_LOADING_STATE';

export default {
    SET_CATEGORIES,
    FETCH_CATEGORIES,
    CREATE_CATEGORY,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_ACTION,
    PUSH_CATEGORY,
    SEARCH_CATEGORY,
    DELETE_CATEGORY,
    SET_LOADING_STATE
};