import React from "react";
import {useSelector} from "react-redux";

import {selectBooksState} from "store/ducks/books/selectors";

import "./styles.scss";

export default function BookSidebar({categories, filterNews, filterCategory}) {
    const news = useSelector(selectBooksState);

    return (
        <div className="book-page-sidebar">
            <h3 className="book-page-sidebar__header">
                Новинки
            </h3>
            <div className="book-page-sidebar__list">
                <button className={news.toggleNew ? "book-page-sidebar__item active" : "book-page-sidebar__item"} onClick={()=>filterNews()}>Все новинки</button>
            </div>
            <h3 className="book-page-sidebar__header">
                Категории
            </h3>
            <div className="book-page-sidebar__list">
                <button className={news.currentCategory === "" ? "book-page-sidebar__item active" : "book-page-sidebar__item"} key={0} onClick={() => filterCategory("")}>Все книги</button>
                <button className={news.currentCategory === "uncategorized" ? "book-page-sidebar__item active" : "book-page-sidebar__item"} key={1} onClick={() => filterCategory("uncategorized")}>Без категории</button>
                {categories
                ? categories.map(item => (
                        <button className={news.currentCategory === item.title.rendered ? "book-page-sidebar__item active" : "book-page-sidebar__item"} key={item.id + 1} onClick={() => filterCategory(item.title.rendered)}>{item.title.rendered}</button>
                    ))
                : null
                }
            </div>
        </div>
    )
}