import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Tag } from 'antd';
import Avatar from 'react-avatar';

import Layout from "components/Layout";
import Header from "components/Header";
import TableItemWrapper from "components/UI/TableItemWrapper";
import Confirm from "components/Confirm";
import Preloader from "components/Preloader";
import {deleteUser, fetchUsers, searchUser} from "store/ducks/users/actions";
import {selectUsersItems, setUsersLoadingStatus} from "store/ducks/users/selectors";

import SearchIcon from "assets/img/search.svg";
import DeleteIcon from "assets/img/trash.svg";
import EditIcon from "assets/img/edit.svg";
import AddIcon from "assets/img/add.svg";
import UserForm from "components/UserForm";

export default function Users() {
    const defaultUser = {
        id: 0,
        user_detail: {
            username: "",
            first: "",
            last: "",
            email: "",
            roles: ["contributor"],
        }
    }
    const [search, setSearch] = React.useState("");
    const [confirmDelete, setConfirmDelete] = React.useState(0);
    const [visible, setVisible] = React.useState(false);
    const [user, setUser] = React.useState(null);

    const dispatch = useDispatch();

    const users = useSelector(selectUsersItems);
    const isLoading = useSelector(setUsersLoadingStatus);

    React.useEffect(() => {
        dispatch(fetchUsers());
    }, [,dispatch]);

    const handleSearch = (e) =>{
        console.log(e)
        setSearch(e);
        dispatch(searchUser(e));
    };

    const actionUser = user => {
        setUser(user)
        setVisible(true)
    }

    const onCloseConfirmDelete = () =>{
        setConfirmDelete(0);
    };
    const onSubmitDelete = () => {
        dispatch(deleteUser(confirmDelete));
        setConfirmDelete(0);
    };

    return (
        <Layout>
            <Header>
                <Input placeholder="Поиск по фамилии" className="custom" value={search} onChange={e=> handleSearch(e.target.value)} prefix={<SearchIcon />} />
            </Header>
            <div className="container">

                <div className="list-wrapper">
                    <div className="page-title-wrapper">
                        <h2>Список пользователей</h2>
                        <Button type="text" icon={<AddIcon />} onClick={() => actionUser(defaultUser)}>
                            Добавить пользователя
                        </Button>
                    </div>
                    {
                        isLoading === "LOADING" ? <Preloader /> :
                            isLoading === "LOADED" ?
                                users ?
                                    users.map(item => (
                                        <TableItemWrapper key={item.id}>

                                            <div className="table-avatar">
                                                <Avatar name={`${item.user_detail.first} ${item.user_detail.last}`} size="40" round={true} color="#ff7a00"/>
                                            </div>
                                            <div className="table-fillname">
                                                {item.user_detail.first} {item.user_detail.last}
                                            </div>
                                            <div className="table-email">
                                                {item.user_detail.email}
                                            </div>
                                            <div className="table-tags">
                                                {
                                                    item.user_detail.roles.map((item, index) => (
                                                        <Tag color={item === "administrator" ? "geekblue" : "green"} key={index}>{item === "administrator" ? "Администратор" : "Участник"}</Tag>
                                                    ))
                                                }

                                            </div>
                                            <div className="table-actions">
                                                {
                                                    item.id !== 1 ?
                                                        <>
                                                            <button className="table-edit" onClick={() => actionUser(item)}><EditIcon /></button>
                                                            <button className="table-delete" onClick={()=>setConfirmDelete(item.id)}><DeleteIcon /></button>
                                                        </>
                                                        : null
                                                }

                                            </div>
                                        </TableItemWrapper>
                                    ))
                                    : null
                                :null
                    }
                </div>
            </div>
            <Confirm
                confirmDeleteText="Удалить пользователя?"
                confirmDelete={confirmDelete}
                onCloseConfirmDelete={onCloseConfirmDelete}
                onSubmitDelete={onSubmitDelete}
            />
            <UserForm user={user} visible={visible} onClose={() => setVisible(false)}/>
        </Layout>
    )
}