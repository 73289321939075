import categories from "./categories";
import auth from "./auth";
import users from "./users";
import books from "./books";
import files from "./files";

const Api = {
    categories,
    auth,
    users,
    books,
    files,
}

export default Api;