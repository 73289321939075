import React from "react";
import {Button, Form, Input, Modal} from "antd";
import { useDispatch, useSelector } from 'react-redux';

import Header from "components/Header";
import TableItemWrapper from "components/UI/TableItemWrapper";
import Layout from "components/Layout";
import Confirm from "components/Confirm";
import Preloader from "components/Preloader";

import {fetchCategories, createCategory, updateCategoryRequest, deleteCategory, searchCategory} from "store/ducks/catagories/actions";
import {selectLoadingStatus, selectCategoriesItems} from "store/ducks/catagories/selectors";

import SearchIcon from "assets/img/search.svg";
import AddIcon from "assets/img/add.svg";
import EditIcon from "assets/img/edit.svg";
import DeleteIcon from "assets/img/trash.svg";
import {getSlug} from "core/utils";


export default function Categories() {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(0);
    const [modalTitle, setModalTitle] = React.useState("Добавить категорию");

    const [search, setSearch] = React.useState("");

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const categories = useSelector(selectCategoriesItems);
    const isLoading = useSelector(selectLoadingStatus);

    React.useEffect(() => {
        dispatch(fetchCategories());
    }, [, dispatch]);


    const showModal = (type, item) => {
        if (type === "create") {
            setModalTitle("Добавить категорию");
            form.setFieldsValue( {
                id: 0,
                title: "",
                slug: ""
            })
        } else {
            setModalTitle("Редактировать категорию")
            form.setFieldsValue( {
                id: item.id,
                title: item.title.rendered,
                slug: item.slug
            })
        }

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.id === 0) {
            dispatch(createCategory(values));
        } else {
            dispatch(updateCategoryRequest(values));
        }

        setIsModalVisible(false);
        form.setFieldsValue( {
            title: "",
            slug: ""
        });

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onCloseConfirmDelete = () =>{
        setConfirmDelete(0);
    };

    const handleSearch = (e) =>{
        console.log(e)
        setSearch(e);
        dispatch(searchCategory(e));
    };

    const onSubmitDelete = () => {
        dispatch(deleteCategory(confirmDelete));
        setConfirmDelete(0);
    };

    const generateSlug = (event) => {
        form.setFieldsValue( {
            title: event.target.value,
            slug: getSlug(event.target.value, {lang: 'ru'})
        })
    }

    return (
        <Layout>
            <Header>
                <Input placeholder="Поиск по названию" className="custom" value={search} onChange={e=> handleSearch(e.target.value)} prefix={<SearchIcon />} />
            </Header>
            <div className="container">

                <div className="list-wrapper">
                    <div className="page-title-wrapper">
                        <h2>Список категорий</h2>
                        <Button type="text" icon={<AddIcon />} onClick={() => showModal("create")}>
                            Добавить категорию
                        </Button>
                    </div>
                    {
                        isLoading === "LOADING" ? <Preloader /> :
                            isLoading === "LOADED" ?
                                categories ?
                                    categories.map(item => (
                                        <TableItemWrapper key={item.id}>

                                            <div className="table-category">
                                                {item.title.rendered}
                                            </div>
                                            <div className="table-category">
                                                {item.slug}
                                            </div>
                                            <div className="table-actions">
                                                <button className="table-edit" onClick={() => showModal("edit", item)}><EditIcon /></button>
                                                <button className="table-delete" onClick={()=>setConfirmDelete(item.id)}><DeleteIcon /></button>
                                            </div>
                                        </TableItemWrapper>
                                    ))
                                    : null
                                :null
                    }
                </div>
            </div>
            <Modal title={modalTitle} centered visible={isModalVisible} onCancel={handleCancel} footer={null}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Id"
                        name="id"
                        hidden
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Название"
                        name="title"
                        rules={[ {required: true,message: 'Уажите название!',},]}
                    >
                        <Input onChange={event => generateSlug(event)}/>
                    </Form.Item>
                    <Form.Item
                        label="Код"
                        name="slug"
                    >
                        <Input disabled />
                    </Form.Item>
                    <div className="button-wrapper">
                        <Form.Item>
                            <Button htmlType="submit">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            <Confirm
                confirmDeleteText="Удалить категорию?"
                confirmDelete={confirmDelete}
                onCloseConfirmDelete={onCloseConfirmDelete}
                onSubmitDelete={onSubmitDelete}

            />
        </Layout>
    )
}