import { combineReducers } from 'redux';
import { categoriesReducer } from './ducks/catagories/reducer';
import { usersReducer } from './ducks/users/reducer';
import { booksReducer } from './ducks/books/reducer';

export const rootReducer = combineReducers({
    categories: categoriesReducer,
    users: usersReducer,
    books: booksReducer,
});
