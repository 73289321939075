import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Button} from "antd";

import "./styles.scss";

import LogoIcon from "assets/img/logo.svg";
import LogOffIcon from "assets/img/log_off.svg";
import UserIcon from "assets/img/user.svg";
import UsersIcon from "assets/img/users.svg";
import CategoryIcon from "assets/img/category.svg";
import BooksIcon from "assets/img/books.svg";

import {selectMe} from "store/ducks/users/selectors";
import UserForm from "components/UserForm";
import {logout} from "store/ducks/users/actions";


export default function Header({children}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visible, setVisible] = React.useState(false);
    const me = useSelector(selectMe);

    const [user, setUser] = React.useState(null);

    const editSelfUser = () => {
        setUser(me)
        setVisible(true)
    }
    const onLogout = () => {
        dispatch(logout());
        history.push('/login')
    }
    return (
        <div className="inner-page-header">
            <div className="container">
                <div className="header-logo-wrapper">
                    <LogoIcon />
                </div>
                <div className="header-search-wrapper">
                    {children}
                </div>
                <div className="header-actions-wrapper">
                    {
                        Object.keys(me).length > 0 && me.user_detail.roles[0] === "administrator" ? (
                            <>
                                <Button type="text" icon={<BooksIcon />} onClick={() => {history.push('/')}} className={(history.location.pathname === "/" ? 'btn-active' : 'def')}>
                                    Книги
                                </Button>
                                <Button type="text" icon={<CategoryIcon />} onClick={() => {history.push('/categories')}} className={(history.location.pathname === "/categories" ? 'btn-active' : 'def')}>
                                    Категории
                                </Button>

                                <Button type="text" icon={<UsersIcon />} onClick={() => {history.push('/users')}} className={(history.location.pathname === "/users" ? 'btn-active' : 'def')}>
                                    Пользователи
                                </Button>
                            </>
                        ): null
                    }

                    <Button type="text" icon={<UserIcon />} onClick={editSelfUser}>
                        Профиль
                    </Button>
                    <Button type="text" icon={<LogOffIcon />} onClick={() => onLogout()}>
                        Выход
                    </Button>
                </div>

            </div>
            <UserForm user={user} visible={visible} onClose={() => setVisible(false)}/>


        </div>
    )
}