import produce, {current} from 'immer';
import types from "./types";


const initialBooksState = {
    defaultItems: [],
    items: [],
    LoadingStatus: "NEVER",
    toggleNew: false,
    currentCategory: ""
};

export const booksReducer = produce((draft, action) => {
    const currentStateData = current(draft)

    switch (action.type) {
        case types.SET_BOOKS:
            let firstArray = action.payload.data.map(item => {
                    if (item.acf.favoriter_users && item.acf.favoriter_users.indexOf(action.payload.user)) {
                        item.favourite = true;
                        return item;
                    }
                    return item;
                }
            );
            let secondArray = firstArray.map(item => {
                    if (item.acf.reader_list && item.acf.reader_list.indexOf(action.payload.user)) {
                        item.readed = true;
                        return item;
                    }
                    return item;
                }
            );
            let threeArray = secondArray.map(item => {
                    if (item.acf.now_read && (item.acf.now_read.find(item => item.ID === action.payload.user.id) !== undefined)) {
                        item.reading = true;

                        return item;
                    }
                    return item;
                }
            );
            draft.items = threeArray;
            draft.defaultItems = threeArray;
            break;

        case types.FETCH_BOOKS:
            draft.items = [];
            break;

        case types.SET_LOADING_STATE:
            draft.LoadingStatus = action.payload;
            break;

        case types.SEARCH_BOOK:
            draft.toggleNew = false
            draft.currentCategory = ""
            if (action.payload !== "") {
                draft.items = currentStateData.defaultItems.filter(item => (item.title.rendered.toLowerCase().includes(action.payload.toLowerCase()) || item.acf.writer.toLowerCase().includes(action.payload.toLowerCase())));
            } else {
                draft.items = currentStateData.defaultItems
            }
            break;

        case types.TOGGLE_NEW:
            draft.toggleNew = !currentStateData.toggleNew
            draft.currentCategory = ""
            if (!currentStateData.toggleNew) {
                draft.items = currentStateData.defaultItems.filter(item => (item.acf.new === true));

            } else {
                draft.items = currentStateData.defaultItems
            }
            break;

        case types.FILTER_BY_CATEGORY:
            draft.toggleNew = false;
            if (action.payload !== "") {
                draft.currentCategory = action.payload
                draft.items = currentStateData.defaultItems.filter(item => {
                    console.log(item); return ((item.acf?.category[0]?.post_title || "uncategorized").toLowerCase().includes(action.payload.toLowerCase()))
                });
            } else {
                draft.currentCategory = ""
                draft.items = currentStateData.defaultItems
            }
            break;

        case types.DELETE_BOOK:
            draft.items = draft.items.filter(item => item.id !== action.payload);
            draft.defaultItems = draft.defaultItems.filter(item => item.id !== action.payload);
            break;

        case types.PUSH_BOOK:
            draft.items.unshift(action.payload);
            draft.defaultItems.unshift(action.payload);
            break;

        case types.UPDATE_BOOK_ACTION:
            draft.items = draft.items.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            break;

        case types.TO_FAVOURITE:
            draft.items = draft.items.map(item => {
                if (item.id === action.payload.book) {
                    item.favourite = true;
                    return item;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.book) {
                    item.favourite = true;
                    return item;
                }
                return item;
            });
            break;

        case types.FROM_FAVOURITE:

            draft.items = draft.items.map(item => {
                if (item.id === action.payload.book) {
                    item.favourite = false;
                    return item;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.book) {
                    item.favourite = false;
                    return item;
                }
                return item;
            });
            break;

        case types.GET_BOOK:
            draft.items = draft.items.map(item => {
                if (item.id === action.payload.book) {
                    item.reading = true;
                    item.readed = true;
                    return item;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.book) {
                    item.reading = true;
                    item.readed = true;
                    return item;
                }
                return item;
            });
            break;
        case types.RETURN_BOOK:
            draft.items = draft.items.map(item => {
                if (item.id === action.payload.book) {
                    item.reading = false;
                    item.acf.now_read = item.acf.now_read.filter(item => item.ID !== action.payload.user)
                    return item;
                }
                return item;
            });
            draft.defaultItems = draft.defaultItems.map(item => {
                if (item.id === action.payload.book) {
                    item.reading = false;
                    return item;
                }
                return item;
            });
            break;
        default:
            break;
    }
}, initialBooksState);
